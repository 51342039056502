export const Client = [
    { 
        img:"almity.jpeg",
    },
    { 
        img:"asok.png",
    },
    { 
        img:"bajaj.jpeg",
    },
    { 
        img:"besoto.jpeg",
    },
    { 
        img:"Bharth-bijile.jpg",
    },
    { 
        img:"bosch.png",
    },
    { 
        img:"brakes-india.jpg",
    },
    { 
        img:"chedda.png",
    },
    { 
        img:"comstar.png",
    },
    { 
        img:"CRI.jpeg",
    },
    { 
        img:"danfoss.jpeg",
    },
    { 
        img:"denso.jpeg",
    },
    { 
        img:"DY-AUTO.jpg",
    },
    { 
        img:"ford.png",
    },
    { 
        img:"hero.png",
    },
    { 
        img:"Honda-Logo.jpg",
    },
    { 
        img:"igarashi.jpeg",
    },
    { 
        img:"INEL.jpg",
    },
    { 
        img:"KHMD.jpeg",
    },
    { 
        img:"koyama.jpg",
    },
    { 
        img:"LucasTVS.jpeg",
    },
    { 
        img:"mahli-letrica.jpg",
    },
    { 
        img:"mico.jpeg",
    },
    { 
        img:"mittal.jpeg",
    },
    { 
        img:"paei.jpg",
    },
    { 
        img:"panalfa.jpeg",
    },
    { 
        img:"PranavVikas.jpeg",
    },
    { 
        img:"pranshu.jpeg",
    },
    { 
        img:"prestolite.jpeg",
    },
    { 
        img:"rane.png",
    },
    { 
        img:"raneTRW.jpeg",
    },
    { 
        img:"renault.jpeg",
    },
    { 
        img:"RoyalEnfield.jpeg",
    },
    { 
        img:"seg.png",
    },
    { 
        img:"shakti.jpeg",
    },
    { 
        img:"sicor.png",
    },
    { 
        img:"SparkMinda.jpeg",
    },
    { 
        img:"tafe.png",
    },
    { 
        img:"TVS.jpeg",
    },
    { 
        img:"varroc.jpeg",
    },
    { 
        img:"visteon.jpeg",
    },
    { 
        img:"wheels-india.jpg",
    },
    { 
        img:"whirlpool.jpeg",
    },
    { 
        img:"yamaha.jpg",
    },
    { 
        img:"ABB.jpeg",
    },
];


