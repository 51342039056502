import Homepage1 from '../../assets/Homepage/Landing/1.JPG'
import Homepage2 from '../../assets/Homepage/Landing/2.jpg'
import Homepage3 from '../../assets/Homepage/Landing/3.jpg'
import Homepage4 from '../../assets/Homepage/Landing/4.jpg'
import Homepage5 from '../../assets/Homepage/Landing/5.JPG'

export const data =[
    {
        img: Homepage1,
        title:'',
        Content:'',
    },
    {
        img:Homepage2,
        title:'',
        Content:'',
    },
    {
        img:Homepage3,
        title:'',
        Content:'',
    },
    {
        img:Homepage4,
        title:'',
        Content:'',
    },
    {
        img:Homepage5,
        title:'',
        Content:'',
    },
  ]